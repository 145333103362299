<template>
  <div class="chart-boost-container">
    <div class="chart-content">
      <Form :label-width="85" label-position="right" class="search-one">
        <Row style="margin-bottom: 18px;">
          <Col span="9">
            <Radio-group v-model="searchOne.time_area">
              <Radio label="0">最近一周</Radio>
              <Radio label="1">最近一月</Radio>
              <Radio label="2">最近一个季度</Radio>
              <Radio label="3">最近一年</Radio>
            </Radio-group>
          </Col>
        </Row>
        <Row>
          <Col span="6">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchOne.pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchOne.cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchOne.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleOne()">搜索</Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span="16">
          <div class="chart" ref="barData1"></div>
        </Col>
        <Col span="8">
          <div class="blue-space bg11">
            <Row type="flex" justify="center" align="middle" style="height: 300px;">
              <Col span="8" style="text-align: center;">
                <p>平均数</p>
                <div class="big-num">{{av_1}}条</div>
              </Col>
              <Col span="8" style="text-align: center;">
                <p>中位数</p>
                <div class="big-num">{{av_2}}条</div>
              </Col>
              <Col span="8" style="text-align: center;">
                <p>众数</p>
                <div class="big-num">{{av_3}}条</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
    <div class="chart-content">
      <Form :label-width="85" label-position="right" class="search-two">
        <Row>
          <Col span="9">
            <Radio-group v-model="searchTwo.time_area">
              <Radio label="0">最近一周</Radio>
              <Radio label="1">最近一月</Radio>
              <Radio label="2">最近一个季度</Radio>
              <Radio label="3">最近一年</Radio>
            </Radio-group>
          </Col>
          <Col span="6">
            <Form-item label="选择员工：">
              <Cascader :data="booster" v-model="boosterArr"></Cascader>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleTwo()">搜索</Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span="16">
          <div class="chart" ref="barData2"></div>
        </Col>
        <Col span="8">
          <div class="blue-space bg11">
            <Row type="flex" justify="center" align="middle" style="height: 300px;">
              <Col span="8" style="text-align: center;">
                <p>平均数</p>
                <div class="big-num">{{av_1}}条</div>
              </Col>
              <Col span="8" style="text-align: center;">
                <p>中位数</p>
                <div class="big-num">{{av_2}}条</div>
              </Col>
              <Col span="8" style="text-align: center;">
                <p>众数</p>
                <div class="big-num">{{av_3}}条</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import boostService from '@/services/boostService';
import chartService from '@/services/chartService';
export default {
	data() {
		return {
			searchOne: {
				time_area: '0',
				pid: '',
				cid: '',
				sid: '',
			},
			searchTwo: {
				time_area: '0',
				staff: '',
			},
			barChart1: '',
			barChart2: '',
			xArr1: [],
			yArr1: [],
			xArr2: [],
			yArr2: [],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			booster: [],
			boosterArr: [],
			av_1: '',
			av_2: '',
			av_3: '',
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	watch: {
		boosterArr() {
			this.searchTwo.staff = this.boosterArr[1];
		},
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchOne.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchOne.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchOne.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		boostService.getBoostSelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
			this.booster = data.booster;
		});
	},
	mounted() {
		// echarts实例化
		this.barChart1 = echarts.init(this.$refs.barData1);
		this.barChart2 = echarts.init(this.$refs.barData2);
		// 初始化实例
		let barOptions = {
			title: {
				text: '各门店累积上传数据条数',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '40%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		this.barChart1.setOption(barOptions);
		this.barChart1.showLoading();
		this.barChart2.setOption(barOptions);
		this.barChart2.showLoading();
		this.getChartOne();
		this.getChartTwo();
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchOne.pid];
			this.searchOne.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchOne.cid];
			this.searchOne.sid = '';
		},
		getChartOne() {
			chartService.getChartBoost(this.searchOne).then((data) => {
				this.xArr1 = [];
				this.yArr1 = [];
				data.data_list.forEach((item) => {
					this.xArr1.push(item.done_time);
					this.yArr1.push(item.count);
				});
				this.barChart1.hideLoading();
				this.barChart1.setOption({
					xAxis: {
						data: this.xArr1,
					},
					series: [
						{
							data: this.yArr1,
						},
					],
				});
				this.av_1 = data.av_list[0].av_1;
				this.av_2 = data.av_list[1].av_2;
				this.av_3 = data.av_list[2].av_3;
			});
		},
		getChartTwo() {
			chartService.getChartBoost(this.searchTwo).then((data) => {
				this.xArr2 = [];
				this.yArr2 = [];
				data.data_list.forEach((item) => {
					this.xArr2.push(item.done_time);
					this.yArr2.push(item.count);
				});
				this.barChart2.hideLoading();
				this.barChart2.setOption({
					xAxis: {
						data: this.xArr2,
					},
					series: [
						{
							data: this.yArr2,
						},
					],
				});
				this.av_1 = data.av_list[0].av_1;
				this.av_2 = data.av_list[1].av_2;
				this.av_3 = data.av_list[2].av_3;
			});
		},
		handleOne() {
			this.getChartOne();
		},
		handleTwo() {
			this.getChartTwo();
		},
	},
};
</script>

<style lang="css" scoped>
.chart-boost-container .search-one .ivu-form-item,
.chart-boost-container .search-two .ivu-form-item {
  margin-bottom: 0;
}
.chart-boost-container .ivu-radio-wrapper {
  margin-right: 10px;
  margin-top: 6px;
}
.chart-boost-container .blue-space {
  margin-top: 50px;
  height: 300px;
  font-size: 16px;
  color: #fff;
}
.chart-boost-container .blue-space .big-num {
  font-size: 30px;
  margin-top: 5px;
  word-wrap: break-word;
  line-height: 1;
}
</style>
